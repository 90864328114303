import { useCallback, useEffect, useState } from "react";

/*
 * returns a react state that is written to either "localStorage" or "sessionStorage" when the state is updated.
 * If there is no value store in the storage object, then we initiate it to the default value
 * */
const useStorage = <T>(
    key: string,
    defaultValue: () => T | T,
    storageObject: typeof window.localStorage | typeof window.sessionStorage
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] => {
    const [value, setValue] = useState<T>(() => {
        const jsonValue = storageObject.getItem(key);

        if (jsonValue !== null) return JSON.parse(jsonValue);

        return typeof defaultValue === "function" ? defaultValue() : defaultValue;
    });

    useEffect(() => {
        storageObject.setItem(key, JSON.stringify(value));
    }, [key, value, storageObject]);

    const remove = useCallback(() => {
        storageObject.removeItem(key);
    }, []);

    return [value, setValue, remove];
};

export const useLocalStorage = <T>(key: string, defaultValue: any) => useStorage<T>(key, defaultValue, window.localStorage);

export const useSessionStorage = <T>(key: string, defaultValue: any) => useStorage<T>(key, defaultValue, window.sessionStorage);
