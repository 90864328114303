import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import ReactInputVerificationCode from "react-input-verification-code";
import styled from "styled-components";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";
import { useLocalStorage } from "../../../hooks/useStorage";

// Firebase
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/firebase";

import { Grid, Alert, AlertTitle, Button } from "@mui/material";

export const StyledSeconds = styled.div`
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.002em;
    color: rgba(255, 255, 255, 0.4);
`;

const StyledError = styled.div`
    margin-top: 13px;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.004em;
    color: #d4251c;
`;

const StyledSuccess = styled.div`
    margin-top: 13px;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.004em;
    color: #3fe863;
`;

const StyledReactInputVerificationCode = styled.div`
    display: flex;
    justify-content: center;

    --ReactInputVerificationCode-itemWidth: 14vw;
    --ReactInputVerificationCode-itemHeight: 6vh;
    --ReactInputVerificationCode-itemSpacing: 2vw;

    .ReactInputVerificationCode__item {
        font-size: 16px;
        font-weight: 500;
        color: #fff;

        background: rgba(53, 67, 98, 1);
        border: 1px solid ${({ isInvalid }: any) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
        border-radius: 4px;
        box-shadow: none;
    }

    .ReactInputVerificationCode__item.is-active {
        box-shadow: none;
        border: 1px solid #36c6d9;
    }
`;

const VerifyCode: React.FC<any> = () => {
    const navigate = useNavigate();
    const { setLoading } = useFetchUser();
    // eslint-disable-next-line
    const [email, setEmail, remove] = useLocalStorage("email", "");

    // States
    const [code, setCode] = useState("");
    const [message, setMessage] = useState<any>(null);
    const [errorCount, setErrorCount] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleSubmit = async () => {
        try {
            setLoading(true);

            const verifyCode = httpsCallable(functions, "login-verifyCode");
            const payload = await verifyCode({ collection: "PersonalTrainers", email, code });

            if ((payload.data as any).status === "isSuccess") {
                // Success
                remove();
                return navigate("/changePassword", { replace: true, state: { email } });
            } else {
                // Error
                setCode("");
                setMessage((payload.data as any).status ? (payload.data as any).message : "Error");
                setIsError(true);
                setErrorCount(errorCount + 1);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const generateCode = async () => {
        try {
            setLoading(true);
            const generateCodeFirebase = httpsCallable(functions, "login-generateCode");
            const payload = await generateCodeFirebase({ collection: "PersonalTrainers", email });

            if ((payload.data as any).status === "isError") {
                setIsError(true);
                setMessage((payload.data as any).message);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (code && !code.includes("·")) {
            handleSubmit();
        }
    }, [code]);

    useEffect(() => {
        if (errorCount > 3) {
            setErrorCount(0);
            setLoading(true);

            setTimeout(() => {
                setLoading(false);
                return navigate("/login");
            }, 3000);
        }
    }, [errorCount]);

    useEffect(() => {
        if (email) {
            generateCode();
        }
    }, [email]);

    if (!email) {
        return <Navigate to="/login" replace />;
    } else
        return (
            <div className="verification__content">
                {isSuccess && (
                    <Grid item xs={4}>
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            This is an error alert — <strong>check it out!</strong>
                        </Alert>
                    </Grid>
                )}
                {/* <IonRow>
                    <IonCol>
                        <IonAlert
                            isOpen={isSuccess}
                            onDidDismiss={() => setIsSuccess(false)}
                            header={"Success!"}
                            message={message}
                            // buttons={["Redirect"]}
                        />
                    </IonCol>
                </IonRow> */}

                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <StyledReactInputVerificationCode<any> isInvalid={isError}>
                        <ReactInputVerificationCode
                            autoFocus
                            value={code}
                            placeholder={undefined}
                            length={6}
                            onCompleted={data => {
                                setIsError(false);
                                setIsSuccess(false);
                                setMessage(null);
                                setCode(data);
                            }}
                        />
                    </StyledReactInputVerificationCode>
                </div>

                {isSuccess ||
                    (isError && (
                        <div className="verification__errorContainer">
                            {isError && errorCount < 5 && (
                                <StyledError>
                                    <p className="verification__error">{message}</p>
                                </StyledError>
                            )}
                            {isSuccess && (
                                <StyledSuccess>
                                    <p className="verification__error">{message}</p>
                                </StyledSuccess>
                            )}
                            {isError && errorCount > 0 && errorCount < 5 && (
                                <StyledError>
                                    <p className="verification__error">
                                        {5 - errorCount} {5 - errorCount > 1 ? "tries" : "try"} remaining.
                                    </p>
                                </StyledError>
                            )}
                            {isError && errorCount === 5 && (
                                <StyledError>
                                    <p className="verification__error">Too many attempts. Please try again.</p>
                                </StyledError>
                            )}
                        </div>
                    ))}

                <Button variant="outlined" onClick={() => navigate("/login", { replace: true })} className="verification__button">
                    Back
                </Button>
            </div>
        );
};

export default VerifyCode;
